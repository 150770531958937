import {jsonParse} from "./json";

/**
 * 将WebSocket的回调API包装成Promise模式
 * 方便上层逻辑的调用
 */
export class WebSocketEx {
  private websocket: WebSocket;
  //NOTE: 这里没有处理背压问题,因为服务器消息发送的速率目前是可控的,并不会出现爆内存的问题
  private messageCache: Array<Data> = [];
  //事件通知回调,避免recv方法的无尽空轮询
  private notifyCallback: (() => void) | null = null;

  constructor(websocket: WebSocket) {
    this.websocket = websocket;
    this.websocket.onmessage = this.onMessage;
    this.websocket.onclose = this.onClose;
    this.websocket.onerror = this.onError;
  }

  private onMessage = (event: MessageEvent) => {
    //根据协议规范,只处理text message
    if (typeof event.data !== "string") {
      return;
    }
    //解析数据,使用定制的jsonParse
    const message = jsonParse(event.data);
    switch (message.type) {
      case "text":
        this.messageCache.push(new TextData(message.data.content));
        break;
      case "binary":
        this.messageCache.push(new BinaryData(message.data.base64));
        break;
      case "json":
        this.messageCache.push(message.data);
        break;
      case "completed":
        this.messageCache.push(new CompletedData(message.data.success, message.data.message));
        break;
      default:
        return;
    }
    this.notifyCallback?.();
    this.notifyCallback = null;
  }

  private onClose = () => {
    this.notifyCallback?.();
    this.notifyCallback = null;
  }

  private onError = () => {
    this.notifyCallback?.();
    this.notifyCallback = null;
  }

  /**
   * 使用websocket连接到指定url
   *
   * @param url 指定的url
   */
  static async connect(url: string): Promise<WebSocketEx> {
    const websocket = new WebSocket(url);
    const waiter = new Promise<void>((resolve, reject) => {
      const openCallback = function () {
        websocket.removeEventListener("open", openCallback);
        websocket.removeEventListener("error", errorCallback);
        resolve();
      }
      const errorCallback = function () {
        websocket.removeEventListener("open", openCallback);
        websocket.removeEventListener("error", errorCallback);
        reject(new Error("连接失败"));
      }
      websocket.addEventListener("open", openCallback);
      websocket.addEventListener("error", errorCallback);
    })
    await waiter;
    return new WebSocketEx(websocket);
  }

  async send(data: Data) {
    if (this.websocket.readyState !== WebSocket.OPEN) {
      throw new Error("连接已经断开");
    }
    let type = "text";
    if (data instanceof TextData) {
      type = "text";
    } else if (data instanceof BinaryData) {
      type = "binary";
    } else if (data instanceof CompletedData) {
      type = "completed";
    } else {
      type = "json";
    }
    //NOTE: 小频率发送不需要关心缓冲区的问题
    this.websocket.send(JSON.stringify({type, data}));
  }

  async recv() {
    while (true) {
      //从消息缓存中获取一条消息
      const data = this.messageCache.shift();
      if (data) return data;

      //没有获取到消息
      //判断是不是连接已经断开
      if (this.websocket.readyState !== WebSocket.OPEN) {
        throw new Error("连接已经断开");
      }
      //如果连接存活,那就是数据还没有到达
      //为了防止空轮,注册等待函数
      await new Promise<void>(resolve => this.notifyCallback = resolve);
      //等待函数被激活/调用
      //可能是新的数据到达,也可能是连接断开/关闭了
      //反正进入新的一轮检查就是了
    }
  }

  async close() {
    this.websocket.close(1000); //正常关闭
    this.notifyCallback?.();
    this.notifyCallback = null;
  }
}

export type Data = TextData | BinaryData | CompletedData | any;

export class TextData {
  content: string;

  constructor(content: string) {
    this.content = content;
  }
}

export class BinaryData {
  base64: string;

  constructor(data: ArrayBuffer | ArrayBufferLike | Blob) {
    // @ts-ignore
    this.base64 = btoa([].reduce.call(new Uint8Array(data), (p, c) => p + String.fromCharCode(c), ''));
  }
}

export class CompletedData {
  success: boolean;
  message: string;

  constructor(success: boolean, message: string) {
    this.success = success;
    this.message = message;
  }
}
