import {EventSourceMessage, fetchEventSource, FetchEventSourceInit} from "@microsoft/fetch-event-source";
import {jsonParse} from "./json";
import {getToken} from "../hooks/useToken";

export class EventStream {
  private abortController = new AbortController()
  private error: any;
  private closed = false;

  private eventCache: Array<{ type: string, data: any }> = [];
  private notifyCallback?: () => void;

  constructor(url: string, config?: FetchEventSourceInit) {
    //补充token
    config = config || {};
    config.headers = config.headers || {};
    config.headers["TOKEN"] = config.headers["TOKEN"] || getToken() || "";
    fetchEventSource(
      url,
      {
        ...config,
        onmessage: this.onEvent,
        onerror: this.onError,
        onclose: this.onClose,
        signal: this.abortController.signal,
      }
    ).finally(this.onClose)
  }

  private onEvent = (event: EventSourceMessage) => {
    this.eventCache.push({
      type: event.event,
      data: jsonParse(event.data)
    });
    this.notifyCallback?.();
    this.notifyCallback = undefined;
  }

  private onClose = () => {
    this.closed = true;
    this.notifyCallback?.();
    this.notifyCallback = undefined;
  }

  private onError = (err: any) => {
    this.error = err;
    this.notifyCallback?.();
    this.notifyCallback = undefined;
    //重新抛出,防止重试
    throw err;
  }

  async recv() {
    while (true) {
      const event = this.eventCache.shift();
      if (event) return event;

      if (this.error) throw this.error;
      if (this.closed) return undefined;

      await new Promise<void>(resolve => this.notifyCallback = resolve);
    }
  }

  close() {
    this.abortController.abort();
    this.notifyCallback?.();
    this.notifyCallback = undefined;
  }
}
