import React from 'react';
import ReactDOM from 'react-dom/client';
import {RouterProvider} from "react-router-dom";
import {NextUIProvider} from "@nextui-org/react";
import './index.scss';
import {router} from "./router";
import {toast, Toaster} from "react-hot-toast";
import {APIError} from "./api";
import {ensureAudioCtx} from "./utils/audio";
import TokenProvider from "./hooks/useToken";
import UserProvider from "./hooks/useUser";

//关闭create react app开发环境下的错误提示层
if (process.env.NODE_ENV !== "production") {
  // @ts-ignore
  import("hide-cra-error-overlay").then(({initHideOverlay}) => initHideOverlay());
}

//添加全局错误处理
window.addEventListener("unhandledrejection", async event => {
  console.error('Uncaught error:\n', event.reason);
  //处理异常
  if (event.reason instanceof APIError) {
    //处理好通用问题
    if (event.reason.code === -1 || event.reason.code === -2) {
      event.preventDefault();
      toast.error(event.reason.message)
      return;
    }
  }
})

ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
  .render(
    <NextUIProvider className="h-full w-full">
      <TokenProvider>
        <UserProvider>
          <Toaster/>
          <div className="h-full w-full" onClick={ensureAudioCtx}>
            <RouterProvider router={router}/>
          </div>
        </UserProvider>
      </TokenProvider>
    </NextUIProvider>
  );

