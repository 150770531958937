import {useParams} from "react-router-dom";
import {useList} from "react-use";
import APIClient, {
  DocumentKnowledgeData,
  ImageKnowledgeData,
  Knowledge,
  KnowledgeData,
  KnowledgeKind,
  MarkdownKnowledgeData,
  QAKnowledgeData
} from "../../api";
import React, {ReactNode, useEffect, useState} from "react";
import {useDebounce} from "ahooks";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Dropdown,
  DropdownItem,
  DropdownItemProps,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Spacer,
  Spinner,
  Textarea,
  useDisclosure
} from "@nextui-org/react";
import {FaCheckCircle, FaSearch} from "react-icons/fa";
import classNames from "classnames";
import {wait} from "../../utils/waiter";
import {HiOutlineIdentification} from "react-icons/hi";
import {
  MdDelete,
  MdEdit,
  MdFileDownload,
  MdMoreVert,
  MdOutlineDescription,
  MdOutlineRefresh,
  MdRunningWithErrors
} from "react-icons/md";
import {toast} from "react-hot-toast";
import Upload from "rc-upload";
import {
  AiFillFile,
  AiFillFileImage,
  AiFillFileMarkdown,
  AiFillFilePdf,
  AiFillFileUnknown,
  AiFillFileWord,
  AiOutlineCloudUpload
} from "react-icons/ai";
import {getToken} from "../../hooks/useToken";
import {Tooltip} from "@nextui-org/tooltip";
import Loading from "../../components/Loading";
import {CgSearchLoading} from "react-icons/cg";
import {BsStars} from "react-icons/bs";

function NewQAKnowledgeBlock({knowledgeBaseId, qa, onClose}: {
  knowledgeBaseId: string,
  qa: QAKnowledgeData | null,
  onClose: () => void
}) {
  const [question, setQuestion] = useState(qa?.question ?? "");
  const [answer, setAnswer] = useState(qa?.answer ?? "");
  const [isLoading, setIsLoading] = useState(false);

  async function handleSave() {
    const waiter = wait();
    setIsLoading(true)
    try {
      await APIClient.knowledge.upsertQA(knowledgeBaseId, question, answer, qa?.id ?? "");
      await waiter;
    } finally {
      await waiter;
      setIsLoading(false);
      onClose();
    }
  }

  return (
    <Card className="w-full h-full">
      <CardHeader className="p-5 flex justify-between">
        <div className="text-medium font-bold">{qa ? "修改" : "添加"}问答</div>
      </CardHeader>
      <CardBody className="p-5">
        <Input size="sm" label="提问" endContent={<HiOutlineIdentification size={22}/>}
               value={question}
               onValueChange={(v) => setQuestion(v)}
        />
        <Spacer y={5}/>
        <Textarea minRows={5} label="回答" placeholder="请输入该提问的回答"
                  endContent={<MdOutlineDescription size={22}/>}
                  value={answer}
                  onValueChange={(v) => setAnswer(v)}
        />
      </CardBody>
      <CardFooter className="p-5 space-x-2 flex justify-end">
        <Button size="sm" color="primary" isLoading={isLoading} onClick={handleSave}>
          {isLoading ? '保存中' : '保存'}
        </Button>
        <Button size="sm" color="danger" onClick={onClose}>取消</Button>
      </CardFooter>
    </Card>
  )
}

function KnowledgeIconBlock({kind, size = 18}: { kind: string, size?: number }) {
  const iconMapping = {
    [KnowledgeKind.KnowledgeKindMarkdown]: <AiFillFileMarkdown size={size}/>,
    [KnowledgeKind.KnowledgeKindWordX]: <AiFillFileWord size={size}/>,
    [KnowledgeKind.KnowledgeKindPdf]: <AiFillFilePdf size={size}/>,
    [KnowledgeKind.KnowledgeKindImage]: <AiFillFileImage size={size}/>,
    [KnowledgeKind.KnowledgeKindQA]: <AiFillFileUnknown size={size}/>,
  };

  return iconMapping[kind] || <AiFillFile size={size}/>;
}

function KnowledgeTitleBlock({knowledge}: {
  knowledge: KnowledgeData,
}) {
  const titleLength = knowledge.title.length;
  if (titleLength < 10) return (<div className="text-sm">{knowledge.title}</div>)
  const size = 5;
  return (
    <Tooltip content={knowledge.title} placement={"bottom"} offset={1}>
      <div className="cursor-pointer text-sm">
        {`${knowledge.title.substring(0, size)}...${knowledge.title.substring(knowledge.title.length - 1 - size, knowledge.title.length - 1)}`}
      </div>
    </Tooltip>
  )
}

function KnowledgeBlock({data, onUpdate, children, menuItems}: {
  data: KnowledgeData,
  onUpdate: () => void,
  children: ReactNode,
  menuItems: Array<DropdownItemProps & { label: string }>
}) {
  const [loading, setLoading] = useState(false);

  async function handleDelete() {
    const waiter = wait();
    try {
      setLoading(true);
      await waiter;
      await APIClient.knowledge.del(data.knowledgeBaseId, data.knowledgeId);
    } finally {
      onUpdate();
      await waiter;
      setLoading(false);
    }
  }

  async function cleanVectoringError() {
    const waiter = wait();
    try {
      setLoading(true);
      await waiter;
      await APIClient.knowledge.cleanVectoringError(data.knowledgeBaseId, data.knowledgeId);
    } finally {
      onUpdate();
      await waiter;
      setLoading(false);
    }
  }

  function items(): Array<DropdownItemProps & { label: string }> {
    const items: Array<DropdownItemProps & { label: string }> = [
      ...menuItems
    ];
    if (data.error) {
      items.push(
        {
          key: "delete",
          className: "text-warning",
          color: "warning",
          startContent: (<MdOutlineRefresh size={18}/>),
          onPress: cleanVectoringError,
          label: "重新索引"
        }
      );
    }
    items.push(
      {
        key: "delete",
        className: "text-danger",
        color: "danger",
        startContent: (<MdDelete size={18}/>),
        onPress: handleDelete,
        label: "删除"
      }
    )
    return items;
  }

  return (
    <>
      <div className="w-full h-full border-2 rounded-lg p-4 flex flex-col gap-6">
        <div className="flex justify-end">
          <Dropdown>
            <DropdownTrigger>
              <div className="cursor-pointer">
                <MdMoreVert size={18}/>
              </div>
            </DropdownTrigger>
            <DropdownMenu aria-label="menu" items={items()}>
              {
                (item) => (
                  <DropdownItem key={item.key}
                                color={item.color}
                                className={item.className}
                                startContent={item.startContent}
                                href={item.href}
                                target={item.target}
                                onPress={item.onPress}
                  >{item.label}</DropdownItem>
                )
              }
            </DropdownMenu>
          </Dropdown>
        </div>
        <div className="flex-1 h-full w-full flex justify-center items-center">
          {loading ? (
            <Loading/>
          ) : data.error === null ? (
            <Badge isOneChar variant="faded" shape="circle" placement="bottom-left"
                   color={data.vectored ? 'success' : 'warning'}
                   content={data.vectored ? <FaCheckCircle size={18}/> : <CgSearchLoading size={18}/>}>
              {children}
            </Badge>
          ) : (
            <Tooltip content={data.error}>
              <Badge isOneChar variant="faded" shape="circle" placement="bottom-right"
                     color='danger'
                     content={<MdRunningWithErrors size={18}/>}>
                {children}
              </Badge>
            </Tooltip>
          )}
        </div>
        <div className="flex justify-center">
          <KnowledgeTitleBlock knowledge={data}/>
        </div>
      </div>
    </>
  )
}

function KnowledgeDocumentBlock({data, onUpdate}: {
  data: DocumentKnowledgeData,
  onUpdate: () => void,
}) {
  return (
    <KnowledgeBlock data={data} onUpdate={onUpdate} menuItems={[
      {
        key: "download",
        className: "",
        color: "default",
        startContent: (<MdFileDownload size={18}/>),
        href: `/api/object/${data.objectId}`,
        target: "_blank",
        label: "下载"
      }
    ]}>
      <KnowledgeIconBlock kind={data.kind} size={80}/>
    </KnowledgeBlock>
  );
}

function KnowledgeMarkdownBlock({data, onUpdate}: {
  data: MarkdownKnowledgeData,
  onUpdate: () => void,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [content, setContent] = useState(data.content);
  const {isOpen, onOpen, onOpenChange} = useDisclosure();

  async function handleUpdateContent(onClose: () => void) {
    const waiter = wait();
    setIsLoading(true);
    try {
      await waiter;
      await APIClient.knowledge.updateMarkdownContent(data.knowledgeBaseId, data.id, content);
      onUpdate();
    } finally {
      await waiter;
      setIsLoading(false);
      onClose();
    }
  }

  return (
    <>
      <Modal placement="center" size="5xl" isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {
            (onClose) => (
              <>
                <ModalHeader className="flex flex-col gap-1">修改</ModalHeader>
                <ModalBody>
                  <Textarea label="" placeholder=""
                            value={content} onValueChange={setContent}/>
                </ModalBody>
                <ModalFooter>
                  <Button size="sm" color="primary" isLoading={isLoading}
                          onClick={() => handleUpdateContent(onClose)}>
                    {isLoading ? '修改中' : '修改'}
                  </Button>
                  <Button color="danger" size="sm" onPress={onClose}>取消</Button>
                </ModalFooter>
              </>
            )
          }
        </ModalContent>
      </Modal>
      <KnowledgeBlock data={data} onUpdate={onUpdate} menuItems={[
        {
          key: "edit",
          className: "",
          color: "default",
          startContent: (<MdEdit size={18}/>),
          onPress: onOpen,
          label: "编辑"
        },
        {
          key: "download",
          className: "",
          color: "default",
          startContent: (<MdFileDownload size={18}/>),
          href: `/api/object/${data.objectId}`,
          target: "_blank",
          label: "下载"
        }
      ]}>
        <KnowledgeIconBlock kind={data.kind} size={80}/>
      </KnowledgeBlock>
    </>
  )
}

function KnowledgeQABlock({data, onUpdate}: {
  data: QAKnowledgeData,
  onUpdate: () => void,
}) {
  const {isOpen, onOpen, onOpenChange} = useDisclosure();

  return (
    <>
      <Modal placement="center" size="5xl" isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {
            (onClose) => <NewQAKnowledgeBlock knowledgeBaseId={data.knowledgeBaseId} qa={data} onClose={onClose}/>
          }
        </ModalContent>
      </Modal>
      <KnowledgeBlock data={data} onUpdate={onUpdate} menuItems={[
        {
          key: "edit",
          className: "",
          color: "default",
          startContent: (<MdEdit size={18}/>),
          onPress: onOpen,
          label: "修改",
        }
      ]}>
        <KnowledgeIconBlock kind={data.kind} size={80}/>
      </KnowledgeBlock>
    </>
  )
}

function KnowledgeImageBlock({data, onUpdate}: {
  data: ImageKnowledgeData,
  onUpdate: () => void,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [summary, setSummary] = useState(data.summary);
  const {isOpen, onOpen, onOpenChange} = useDisclosure();

  async function handleUpdateImageSummary(onClose: () => void) {
    const waiter = wait();
    setIsLoading(true);
    try {
      await waiter;
      await APIClient.knowledge.updateImageSummary(data.knowledgeBaseId, data.id, summary);
      onUpdate();
    } finally {
      await waiter;
      setIsLoading(false);
      onClose();
    }
  }

  const [summaryLoading, setSummaryLoading] = useState(false);

  async function summaryImage() {
    if (summaryLoading) return;
    const waiter = wait();
    setSummaryLoading(true);
    try {
      await waiter;
      const summary = await APIClient.knowledge.summaryImage(data.knowledgeBaseId, data.id);
      setSummary(summary);
      onOpen();
    } finally {
      await waiter;
      setSummaryLoading(false);
    }
  }

  return (
    <>
      <Modal placement="center" isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">修改图片描述</ModalHeader>
              <ModalBody>
                <Textarea label="描述" placeholder="请填写图片的描述"
                          value={summary} onValueChange={setSummary}/>
              </ModalBody>
              <ModalFooter className="flex justify-between items-center">
                <div>
                  <Button size="sm" color="secondary"
                          startContent={!summaryLoading && <BsStars/>}
                          isLoading={summaryLoading}
                          onClick={summaryImage}
                  >AI识别</Button>
                </div>
                <div className="flex gap-2">
                  <Button size="sm" color="primary" isLoading={isLoading}
                          onClick={() => handleUpdateImageSummary(onClose)}>
                    {isLoading ? '保存中' : '保存'}
                  </Button>
                  <Button color="danger" size="sm" onPress={onClose}>取消</Button>
                </div>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
      <KnowledgeBlock data={data} onUpdate={onUpdate}
                      menuItems={[{
                        key: "edit",
                        className: "",
                        color: "default",
                        startContent: (<MdEdit size={18}/>),
                        label: "描述",
                        onPress: () => {
                          setSummary(data.summary);
                          onOpen();
                        },
                      }]}
      >
        <div className="h-36 w-48 flex justify-center items-center">
          <img className="max-h-full max-w-full"
               src={`/api/object/${data.objectId}?size=512`}
               alt={data.description ?? 'image'}/>
        </div>
      </KnowledgeBlock>
    </>
  )
}

export default function KnowledgeList() {
  const params = useParams();
  const knowledgeBaseId = params.knowledgeBaseId!;

  const [knowledgeList, setKnowledgeList] = useList<Knowledge>();
  const [timestamp, setTimestamp] = useState(new Date().getTime());
  const [keyword, setKeyword] = useState('');
  const debouncedKeyword = useDebounce(keyword, {wait: 1500})
  useEffect(() => {
    (async () => {
      const knowledgeList = await APIClient.knowledge.list(knowledgeBaseId);
      setKnowledgeList.set(knowledgeList.map(it => {
        it.data.error = it.error;
        return {...it, isDeleting: false}
      }));
    })()
  }, [knowledgeBaseId, timestamp, debouncedKeyword, setKnowledgeList]);

  //定时刷新索引中的数据
  useEffect(() => {
    const interval = setInterval(() => {
      const needFresh = knowledgeList.find(it => !it.data.vectored)
      if (needFresh) {
        setTimestamp(new Date().getTime());
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [knowledgeList]);

  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  return (
    <>
      <Modal placement="center" size="5xl" isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {
            (onClose) => <NewQAKnowledgeBlock knowledgeBaseId={knowledgeBaseId} qa={null} onClose={() => {
              onClose();
              setTimestamp(new Date().getTime());
            }}/>
          }
        </ModalContent>
      </Modal>
      <Card>
        <CardHeader className="px-6 flex justify-between h-16">
          <div className="flex space-x-2">
            <Input variant="bordered" size="sm"
                   labelPlacement={"outside-left"}
                   placeholder="搜索"
                   isClearable
                   startContent={<FaSearch/>}
                   className="max-w-xs"
                   value={keyword}
                   onValueChange={setKeyword}
                   onClear={() => setKeyword('')}
            />
          </div>
          <div className="flex items-center space-x-2">
            <Uploader knowledgeBaseId={knowledgeBaseId} onFresh={() => setTimestamp(new Date().getTime())}/>
            <Button variant="ghost" color="primary" size="sm" onClick={onOpen}>新增问答</Button>
          </div>
        </CardHeader>
      </Card>
      <Spacer y={6}/>
      <div className="grid gap-6 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6">
        {
          knowledgeList.map((it, index) => (
            <div key={it.data.id}
                 className="animate__animated animate__fadeIn animate__faster"
                 style={{animationDelay: `${index * 50}ms`}}>
              {
                (() => {
                  const handleUpdate = () => setTimestamp(new Date().getTime());
                  it.data.kind = it.kind;
                  switch (it.kind) {
                    case KnowledgeKind.KnowledgeKindWordX:
                    case KnowledgeKind.KnowledgeKindPdf:
                      return (
                        <KnowledgeDocumentBlock data={it.data as DocumentKnowledgeData} onUpdate={handleUpdate}/>
                      );
                    case KnowledgeKind.KnowledgeKindMarkdown:
                      return (
                        <KnowledgeMarkdownBlock data={it.data as MarkdownKnowledgeData} onUpdate={handleUpdate}/>
                      );
                    case KnowledgeKind.KnowledgeKindImage:
                      return (
                        <KnowledgeImageBlock data={(it.data as ImageKnowledgeData)} onUpdate={handleUpdate}/>
                      );
                    case KnowledgeKind.KnowledgeKindQA:
                      return (
                        <KnowledgeQABlock data={(it.data) as QAKnowledgeData} onUpdate={handleUpdate}/>
                      );
                  }
                })()
              }
            </div>
          ))
        }
      </div>
    </>
  )
}

function Uploader({knowledgeBaseId, onFresh}: { knowledgeBaseId: string, onFresh: () => void }) {
  const [isLoading, setIsLoading] = useState(false);

  function handleSuccess(body: Record<string, any>) {
    setIsLoading(false);
    const isSuccess = body.success;
    if (!isSuccess) {
      toast.error(body.message || "");
    }
    onFresh();
  }

  function handleError(event: Error, body?: Object) {
    setIsLoading(false);
    toast.error(event.message)
    onFresh();
  }

  return (
    <>
      <Upload
        accept=".docx,.pdf,.jpg,.png,.jpg,.jpeg,.gif,.markdown,.md" multiple
        action={`/api/knowledge-base/${knowledgeBaseId}/knowledge/upload`}
        headers={{"TOKEN": getToken() ?? ""}}
        onSuccess={handleSuccess}
        onError={handleError}
        onProgress={() => setIsLoading(true)}
      >
        <div className={classNames(
          "w-[98px] h-[32px]",
          "border-2 rounded-lg border-secondary",
          "flex justify-center items-center space-x-1",
          "text-secondary",
          "hover:bg-secondary hover:text-white",
          "transition-background transition-colors duration-200 ease-in",
        )}>
          <div className="flex justify-center items-center w-5">
            {isLoading
              ? <Spinner color="secondary" className="scale-80" size="sm"/>
              : <AiOutlineCloudUpload size={18}/>
            }
          </div>
          <div className="text-[12px]">{isLoading ? "正在处理" : "上传文档"}</div>
        </div>
      </Upload>
    </>
  )
}
