import {useParams} from "react-router-dom";
import React, {useEffect, useMemo, useState} from "react";
import APIClient, {Bot, KnowledgeBase, MpBindUpsert} from "../../api";
import {wait} from "../../utils/waiter";
import Loading from "../../components/Loading";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Chip,
  Input,
  Select,
  SelectItem,
  Snippet,
  Spacer,
  Textarea
} from "@nextui-org/react";
import Emoji from "../../components/Emoji";
import {MdOutlineDescription, MdPublish} from "react-icons/md";
import {GrMemory} from "react-icons/gr";
import {HiOutlineIdentification} from "react-icons/hi";
import {FaCode, FaInfinity} from "react-icons/fa6";
import {useList} from "react-use";
import {RiWechat2Line} from "react-icons/ri";
import styled from "styled-components";

export default function BotEditor() {
  const params = useParams();
  const botId = params.botId!;

  async function handleSubmit(bot: Bot) {
    const waiter = wait();
    await APIClient.bot.upsert(bot);
    await waiter;
  }

  async function handleSaveMpBind(mp: MpBindUpsert) {
    const waiter = wait();
    await APIClient.mp.upsert(mp);
    await waiter;
  }

  async function handleDelMpBind(botId) {
    const waiter = wait();
    await APIClient.mp.del(botId);
    await waiter;
  }

  return (
    <div className="h-full flex *:flex-1 space-x-4">
      <div className="h-full">
        <BotSetting botId={botId} onSubmit={handleSubmit}/>
      </div>
      <div className="h-full">
        <BindMp botId={botId} upsert={handleSaveMpBind} del={handleDelMpBind}/>
      </div>
    </div>
  )
}

function BotSetting({botId, onSubmit}: {
  botId: string,
  onSubmit: (bot: Bot) => Promise<void>,
}) {
  const [bot, setBot] = useState<Bot | undefined>(undefined);
  useEffect(() => {
    (async () => {
      const waiter = wait();
      const bot = await APIClient.bot.get(botId);
      await waiter;
      setBot(bot);
    })()
  }, [botId]);


  const [isSubmitting, setIsSubmitting] = useState(false);

  //加载中
  if (typeof bot === "undefined") return <Loading/>

  return (
    <Card className="h-full w-full flex flex-col">
      <CardHeader className="px-5 flex h-16">
        <div className="flex items-center space-x-4">
          <Emoji picker size={38} icon={bot.icon} onIconChanged={icon => setBot({...bot, icon})}/>
          <div>
            <div className="text-sm font-bold">{bot.name}</div>
            <div
              className="text-[12px] max-w-48 whitespace-nowrap overflow-ellipsis overflow-hidden">{bot.description}</div>
          </div>
        </div>
        <div className="flex-1"></div>
        <Button isLoading={isSubmitting}
                endContent={<MdPublish size={18}/>}
                variant="ghost" color="primary" size="sm"
                onClick={async () => {
                  try {
                    setIsSubmitting(true)
                    await onSubmit(bot)
                  } finally {
                    setIsSubmitting(false)
                  }
                }}>
          发布
        </Button>
      </CardHeader>
      <CardBody className="flex flex-col overflow-hidden">
        <div className="rounded-xl bg-gray-50 p-3">
          <div className="flex items-center space-x-6">
            <div className="flex items-center space-x-2">
              <GrMemory className="text-secondary" size={16}/>
              <div className="text-sm font-bold">知识库</div>
            </div>
            <div className="flex-1">
              <KnowledgeBaseSelect knowledgeBaseIds={bot.knowledgeBases}
                                   onKnowledgeBasesChanged={(ids) => setBot({...bot, knowledgeBases: ids})}/>
            </div>
          </div>
        </div>
        <Spacer y={3}/>
        <TextAreaWrapper className="flex-1">
          <Textarea color="primary" variant="bordered" placeholder="请在此输入AI指令"
                    startContent={<div className="text-primary"><FaCode size={18}/></div>}
                    disableAutosize={true}
                    value={bot.instructions}
                    onValueChange={(v) => setBot({...bot, instructions: v})}
          />
        </TextAreaWrapper>
        <Spacer y={3}/>
        <div className="rounded-xl bg-gray-50 p-3 overflow-y-auto">
          <BaseSetting bot={bot} onBotChanged={setBot}/>
        </div>
      </CardBody>
    </Card>
  );
}

function BindMp({botId, upsert, del}) {
  useEffect(() => {
    (async () => {
      const waiter = wait();
      try {
        setLoading(true)
        const mp = await APIClient.mp.get(botId);
        await waiter;
        setMp({
          botId,
          appId: mp?.appId ?? '',
          appSecret: mp?.appSecret ?? '',
          token: mp?.token ?? '',
          subscribeReplay: mp?.subscribeReplay ?? ''
        });
      } finally {
        await waiter;
        setLoading(false)
      }
    })()
  }, [botId]);

  const [mp, setMp] = useState<MpBindUpsert>(
    {
      botId: '',
      appId: '',
      appSecret: '',
      token: '',
      subscribeReplay: ''
    });
  const [saveLoading, setSaveLoading] = useState(false);
  const [delLoading, setDelLoading] = useState(false);

  const [loading, setLoading] = useState(true);

  if (loading) return <Loading/>
  return (
    <Card>
      <CardHeader className="px-5 flex h-16">
        <div className="flex items-center space-x-4">
          <Chip startContent={<RiWechat2Line size={18}/>}
                variant="bordered" color="success" size="lg"
          >微信公众号</Chip>
        </div>
        <div className="flex-1"></div>
        <div className="flex items-center space-x-2">
          <Button variant="ghost" color="primary" size="sm" isLoading={saveLoading}
                  onClick={async () => {
                    try {
                      setSaveLoading(true)
                      await upsert(mp)
                    } finally {
                      setSaveLoading(false)
                    }
                  }}>
            {saveLoading ? '保存中' : '保存'}
          </Button>
          <Button variant="ghost" color="danger" size="sm" isLoading={delLoading}
                  onClick={async () => {
                    try {
                      setDelLoading(true)
                      await del(botId)
                      setMp({
                        botId: '',
                        appId: '',
                        appSecret: '',
                        token: '',
                        subscribeReplay: ''
                      })
                    } finally {
                      setDelLoading(false)
                    }
                  }}>
            {delLoading ? '删除中' : '删除'}
          </Button>
        </div>
      </CardHeader>
      <CardBody>
        <Snippet tooltipProps={{content: "服务器地址(URL)"}}>
          {`${window.location.protocol}//${window.location.host}/api/mp/${botId}`}
        </Snippet>
        <Spacer y={2}/>
        <Input label="AppId" variant="bordered"
               isRequired value={mp.appId}
               onValueChange={(v) => setMp({...mp, appId: v, botId: botId})}
        />
        <Spacer y={2}/>
        <Input label="AppSercet" variant="bordered"
               isRequired value={mp.appSecret}
               onValueChange={(v) => setMp({...mp, appSecret: v})}
        />
        <Spacer y={2}/>
        <Input label="Token" variant="bordered"
               isRequired value={mp.token}
               onValueChange={(v) => setMp({...mp, token: v})}
        />
        <Spacer y={2}/>
        <Textarea label="关注回复" variant="bordered"
                  value={mp.subscribeReplay}
                  onValueChange={(v) => setMp({...mp, subscribeReplay: v})}
        />
      </CardBody>
    </Card>
  );
}

function BaseSetting({bot, onBotChanged}: {
  bot: Bot, onBotChanged: (bot: Bot) => void
}) {
  const [models, setModels] = useState<string[]>([]);
  useEffect(() => {
    (async () => {
      const models = await APIClient.bot.models();
      setModels(models)
    })()
  }, []);
  const selectedModel = useMemo(() =>
      models.some(m => m === bot.model)
        ? [bot.model]
        : [],
    [models, bot],
  );

  return (
    <>
      <div className="flex *:flex-1 space-x-4">
        <Input size="sm" label="名称" labelPlacement="outside"
               endContent={<HiOutlineIdentification className="text-amber-500" size={22}/>}
               value={bot.name}
               onValueChange={(v) => onBotChanged({...bot, name: v})}
        />
        <Select size="sm" label="模型" labelPlacement="outside" placeholder="请选择LLM模型"
                endContent={<FaInfinity className="text-secondary" size={22}/>}
                selectedKeys={selectedModel}
                onSelectionChange={(selection) => {
                  selection = selection as Set<string>
                  const selected = Array.from(selection.values()).map(it => it.toString());
                  if (selected.length > 0)
                    onBotChanged({
                      ...bot,
                      model: selected[0]
                    })
                }}
        >
          {models.map(m => <SelectItem key={m} value={m}>{m}</SelectItem>)}
        </Select>
      </div>
      <Spacer y={2}/>
      <Textarea size="sm" label="描述" placeholder="一个很有用的机器人"
                endContent={<MdOutlineDescription className="text-cyan-600" size={22}/>}
                minRows={2} maxRows={3}
                value={bot.description}
                onValueChange={(v) => onBotChanged({...bot, description: v})}
      />
    </>
  );
}

function KnowledgeBaseSelect({knowledgeBaseIds, onKnowledgeBasesChanged}: {
  knowledgeBaseIds: string[],
  onKnowledgeBasesChanged: (ids: string[]) => void
}) {
  const [knowledgeBases, setKnowledgeBases] = useList<KnowledgeBase>();
  useEffect(() => {
    (async () => {
      const knowledgeBases = await APIClient.knowledgeBase.list();
      setKnowledgeBases.set(knowledgeBases);
    })()
  }, [setKnowledgeBases]);
  const selectedKnowledgeBases = useMemo(() => knowledgeBaseIds.filter(id => knowledgeBases.some(
    k => k.id === id
  )), [knowledgeBases, knowledgeBaseIds]);

  return (
    <Select labelPlacement="outside-left"
            items={knowledgeBases} selectionMode="multiple" isMultiline aria-label="请选择嵌入的知识库"
            render-value={
              selected =>
                selected.map(it => <Chip key={it.key} variant="flat" className="m-1" size="sm"
                                         color="secondary">{it.textValue}</Chip>)
            }
            selectedKeys={selectedKnowledgeBases}
            onSelectionChange={(selection) => {
              selection = selection as Set<string>;
              onKnowledgeBasesChanged(Array.from(selection.values()).map(it => it.toString()))
            }}
    >
      {knowledgeBase => (
        <SelectItem key={knowledgeBase.id} value={knowledgeBase.id} textValue={knowledgeBase.name}>
          <div className="flex items-center space-x-4 h-6">
            <div className="text-secondary"><GrMemory/></div>
            <div>{knowledgeBase.name}</div>
          </div>
        </SelectItem>
      )}
    </Select>
  )

}

const TextAreaWrapper = styled.div`
  > div {
    height: 100% !important;

    > div {
      height: 100% !important;

      > div > textarea {
        height: 100% !important;
      }
    }
  }
`;
