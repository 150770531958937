import Tile from "react-parallax-tilt";
import {Button, Card, CardBody, CardFooter, CardHeader, Input, Spinner} from "@nextui-org/react";
import {BsRobot} from "react-icons/bs";
import React, {useState} from "react";
import classNames from "classnames";
import {FaUser} from "react-icons/fa";
import {wait} from "../utils/waiter";
import APIClient from "../api";
import {useNavigate} from "react-router-dom";
import {useSearchParam} from "react-use";
import {useUser} from "../hooks/useUser";

export default function Phone() {
  const navigate = useNavigate();
  const returnUrl = useSearchParam("returnUrl");

  const [, setUser] = useUser();

  const [phone, setPhone] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  async function handleBind() {
    const waiter = wait(2000);
    try {
      setIsLoading(true);
      await APIClient.user.bindPhone(phone);
      setUser(undefined);
      await waiter;
      if (returnUrl)
        navigate(decodeURIComponent(returnUrl), {replace: true});
      else
        navigate("/", {replace: true});
    } finally {
      await waiter;
      setIsLoading(false);
    }
  }

  return (
    <div className="h-full w-full flex justify-center items-center">
      <Tile tiltMaxAngleX={5} tiltMaxAngleY={5} transitionSpeed={20000} scale={1.03}>
        <Card>
          <CardHeader className="pt-10 pb-4 flex-row justify-evenly items-center animate__animated animate__fadeInDown">
            <BsRobot className="animate__animated animate__fadeIn" size={54}/>
            <div>
              <h4 className="font-bold text-large animate__animated animate__flipInX">大宝</h4>
              <small className="text-default-500 uppercase animate__animated animate__fadeIn animate__delay-1s">
                AI for everyone!
              </small>
            </div>
          </CardHeader>
          <CardBody className="px-14 py-5 overflow-hidden">
            <Input size="md"
                   className={
                     classNames(
                       "w-[280px] animate__animated",
                       isLoading ? "animate__fadeOutLeftBig" : "animate__fadeInLeft"
                     )
                   }
                   label="手机号码" labelPlacement="outside" endContent={<FaUser/>}
                   value={phone} onValueChange={setPhone}
            />
          </CardBody>
          <CardFooter className="flex justify-center p-4 pb-6 h-20 overflow-visible">
            <div
              style={{transition: `all 0.5s ease-out`, transform: isLoading ? `translateY(-80px)` : `translateY(0px)`}}>
              {
                isLoading ?
                  <div className="flex flex-col justify-center animate__animated animate__fadeIn">
                    <Spinner/>
                    <div className="mt-4 text-sm font-bold">绑定中</div>
                  </div>
                  :
                  <Button
                    className="animate__animated animate__fadeInUp"
                    color="primary" size="md" onClick={handleBind}>绑 定</Button>
              }
            </div>
          </CardFooter>
        </Card>
      </Tile>
    </div>
  )
}
