import {Button} from "@nextui-org/react";
import {useNavigate} from "react-router-dom";

export default function Home() {
  const navigate = useNavigate();

  return (
    <div className="h-full w-full flex justify-center items-center">
      <Button color="primary" variant="shadow" onClick={() => navigate("/console")}>开始</Button>
    </div>
  )
}
