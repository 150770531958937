import MarkdownIt from "markdown-it";
import hljs from "highlight.js";
import "highlight.js/styles/default.css";
import plainTextPlugin from "markdown-it-plain-text";

const markdownIt = new MarkdownIt({
  //高亮插件
  highlight: function (str, lang) {
    if (lang && hljs.getLanguage(lang)) {
      try {
        const highlightResult = hljs.highlight(str, {language: lang, ignoreIllegals: true});
        const languageTag = `<div style="position: absolute; top: 5px; right: 10px;font-size: 12px;font-weight: bold">${lang}</div>`
        return `<pre style="position: relative">${languageTag}<code class="hljs">${highlightResult.value}</code></pre>`;
      } catch (__) {
      }
    }
    return '<pre><code class="hljs">' + markdownIt.utils.escapeHtml(str) + '</code></pre>';
  }
});
markdownIt.use(plainTextPlugin);

//连接渲染
const defaultLinkRender = markdownIt.renderer.rules.link_open || function (tokens, idx, options, env, self) {
  return self.renderToken(tokens, idx, options);
};
markdownIt.renderer.rules.link_open = function (tokens, idx, options, env, self) {
  tokens[idx].attrSet('target', '_blank');
  return defaultLinkRender(tokens, idx, options, env, self);
};

//图片渲染
const defaultImageRender = markdownIt.renderer.rules.image || function (tokens, idx, options, env, self) {
  return self.renderToken(tokens, idx, options);
};
markdownIt.renderer.rules.image = function (tokens, idx, options, env, self) {
  //获取原来的url
  const originUrl = tokens[idx].attrGet('src');
  if (originUrl) {
    //改写url
    const url = originUrl.startsWith("object://") ? originUrl.replace("object://", "/object/") : `/api/object/proxy/${originUrl}`
    tokens[idx].attrSet('src', url)
  }
  return defaultImageRender(tokens, idx, options, env, self);
};

/**
 * 渲染markdown到html
 * @param md markdown内容
 */
export function markdownRender(md: string): string {
  return markdownIt.render(md)
}

/**
 * 渲染markdown到纯文本
 * @param md markdown内容
 */
export function markdownPlainText(md: string): string {
  //去除图片
  //eslint-disable-next-line
  md = md.replace(/!\[([^\[\]]*)\]\((.*?)\)/gm, '');
  //去除链接
  //eslint-disable-next-line
  md = md.replace(/\[([^\[\]]*)\]\((.*?)\)/gm, '');
  //去除无意义的空白
  md = md.trim();
  markdownIt.render(md);
  return (markdownIt as any).plainText.trim();
}
