import {useEffect, useState} from "react";
import {useList} from "react-use";
import APIClient, {KnowledgeBase, KnowledgeBaseUpsert} from "../../api";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Input,
  Modal,
  ModalContent,
  useDisclosure
} from "@nextui-org/react";
import {useNavigate} from "react-router-dom";
import Emoji from "../../components/Emoji";
import {SlOptions} from "react-icons/sl";
import {wait} from "../../utils/waiter";
import {FaPlus} from "react-icons/fa";
import {HiOutlineIdentification} from "react-icons/hi";

function KnowledgeBaseBlock({knowledgeBase, onDelete, onEdit}: {
  knowledgeBase: KnowledgeBase,
  onDelete: () => void,
  onEdit: () => void
}) {
  const navigate = useNavigate();

  const [isDeleting, setIsDeleting] = useState(false);

  async function handleDelete() {
    const waiter = wait();
    try {
      setIsDeleting(true);
      await APIClient.knowledgeBase.del(knowledgeBase.id)
      await waiter;
      onDelete();
    } finally {
      await waiter;
      setIsDeleting(false)
    }
  }

  return (
    <Card shadow="sm" className="w-full h-48">
      <CardHeader className="p-4 flex justify-between">
        <Emoji icon={knowledgeBase.icon}/>
        <div className="text-sm font-bold">{knowledgeBase.name}</div>
        <div
          className="cursor-pointer p-2 rounded-3xl hover:shadow"
          onClick={onEdit}>
          <SlOptions/>
        </div>
      </CardHeader>
      <CardBody className="p-4"></CardBody>
      <CardFooter className="space-x-2 flex justify-end">
        <Button variant="ghost" color="primary" size="sm"
                onClick={() => navigate(`/console/knowledge-base/${knowledgeBase.id}/knowledge`)}>管理</Button>
        <Button variant="ghost" isLoading={isDeleting} color="danger" size="sm" onClick={handleDelete}>删除</Button>
      </CardFooter>
    </Card>
  )
}

function NewKnowledgeBaseBlock({knowledgeBase, onClose}: {
  knowledgeBase: KnowledgeBaseUpsert | undefined,
  onClose: () => void
}) {
  const [icon, setIcon] = useState(knowledgeBase?.icon ?? ':book:');
  const [name, setName] = useState(knowledgeBase?.name ?? '');

  const [isLoading, setIsLoading] = useState(false);

  const action = knowledgeBase?.id ? '修改' : '创建';

  async function handleSave() {
    const waiter = wait();
    setIsLoading(true)
    try {
      await APIClient.knowledgeBase.upsert({name, icon, id: knowledgeBase?.id ?? ''});
      await waiter;
    } finally {
      await waiter;
      setIsLoading(false);
      onClose();
    }
  }

  return (
    <Card className="w-full">
      <CardHeader className="p-5 flex justify-between">
        <div className="text-medium font-bold">{action}知识库</div>
      </CardHeader>
      <CardBody className="p-5">
        <div className="flex items-center space-x-4">
          <Emoji size={36} picker icon={icon} onIconChanged={setIcon}/>
          <Input size="sm" label="名称" endContent={<HiOutlineIdentification size={22}/>}
                 value={name} onValueChange={setName}/>
        </div>
      </CardBody>
      <CardFooter className="p-5 space-x-2 flex justify-end">
        <Button size="sm" color="primary" isLoading={isLoading} onClick={handleSave}>
          {action}{isLoading ? '中' : ''}
        </Button>
        <Button size="sm" color="danger" onClick={onClose}>取消</Button>
      </CardFooter>
    </Card>
  );
}

export default function KnowledgeBaseList() {
  const [knowledgeBases, setKnowledgeBases] = useList<KnowledgeBase>();
  const [timestamp, setTimestamp] = useState(new Date().getTime());
  useEffect(() => {
    (async () => {
      const knowledgeBases = await APIClient.knowledgeBase.list();
      setKnowledgeBases.set(knowledgeBases);
    })()
  }, [setKnowledgeBases, timestamp])

  const [currentKnowledgeBase, setCurrentKnowledgeBase] = useState<KnowledgeBaseUpsert | undefined>(undefined)
  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  return (
    <>
      <Modal placement="center" size="sm" isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {
            (onClose) => <NewKnowledgeBaseBlock knowledgeBase={currentKnowledgeBase} onClose={() => {
              onClose();
              setTimestamp(new Date().getTime());
            }}/>
          }
        </ModalContent>
      </Modal>
      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
        <Card className="w-full h-48 flex justify-center items-center animate__animated animate__fadeIn"
              isPressable isHoverable
              shadow="sm"
              onClick={() => {
                setCurrentKnowledgeBase(undefined);
                onOpen();
              }}>
          <FaPlus size={32}/>
        </Card>
        {
          knowledgeBases.map((it, index) => (
            <div key={it.id}
                 className="animate__animated animate__fadeIn animate__faster"
                 style={{animationDelay: `${index * 50}ms`}}>
              <KnowledgeBaseBlock knowledgeBase={it} onDelete={() => setTimestamp(new Date().getTime())} onEdit={() => {
                setCurrentKnowledgeBase(it);
                onOpen();
              }}/>
            </div>
          ))
        }
      </div>
    </>
  )
}
